<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Tipo de Venda" : "Cadastrar Tipo de Venda" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Tipos de Venda</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12">
              <span class="primary--text">Descrição *</span>
              <v-text-field
                v-model="dados.descricao"
                :rules="fieldRules"
                required
                placeholder="Descrição"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn depressed dark text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow";
export default {
  components: {
    BackArrow,
  },
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        descricao: "",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/tiposvenda/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/tiposvenda/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/tipos-de-venda"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/tiposvenda/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/tipos-de-venda");
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
};
</script>

<style src="../style.vue"></style>
